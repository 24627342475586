<template>
    <div class="page" id="reorganize">
        <div class="bg-white">
        <div class="tabsCls">
            <el-tabs type="card" v-model="selectTab" @tab-click="handleClick">
                <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                             :name="item.value"></el-tab-pane>
            </el-tabs>
        </div>
        <el-form size="small"
                 @keyup.enter.native="getDataList(1,1)"
                 class="query-form" ref="inputForm" :inline="true" :model="inputForm" label-width="80px">
            <el-form-item label="文献名称" prop="docuName">
                <el-input v-model.trim="inputForm.docuName"
                          placeholder="请输入文献名称(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item label="作者" v-if="selectTab == 1" prop="docuAuthor">
                <el-input v-model.trim="inputForm.docuAuthor"
                          placeholder="请输入作者(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item label="藏品名称" prop="collectionName">
                <el-input v-model.trim="inputForm.collectionName"
                          placeholder="请输入藏品名称(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <el-form-item v-if="selectTab == 2" label="归属账目" prop="entryState">
                <el-select
                        v-model="inputForm.entryState"
                        clearable
                        placeholder="请选择归属账目"
                        style="width: 100%"
                >
                    <el-option
                            v-for="item in appraisalTypeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="selectTab == 2" label="藏品编号" prop="dataId">
                <el-input v-model.trim="inputForm.dataId"
                          placeholder="请输入藏品编号(限50字)"
                          maxlength="50" clearable></el-input>
            </el-form-item>
            <div class="flex_b_c">
                <div>
                    <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
                    <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
                </div>
                <div>
                    <el-button type="primary" size="small"
                               v-show="hasPermissionButton(`collection:business:${typePage}:add`)"
                               icon='el-icon-plus' @click="addData(0)">新增
                    </el-button>
                </div>
            </div>
        </el-form>
        </div>
        <div class="bg-white" style="margin-top: 10px">
            <div class="text_right">
                <el-button size="small" v-show="hasPermissionButton(`collection:business:${typePage}:batchExpor`)"
                           @click="bulkExport()">
                    <i class="icon-piliangdaochu iconfont buIcon"/>
                    批量导出
                </el-button>
            </div>
            <el-table
                    v-show="selectTab == 2"
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable1"
                    :height="'calc(100vh - 360px)'"
                    class="table"
                    :row-key="'dataId'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="name" label="图标" width="120px">
                    <template slot-scope="scope">
                        <div class="flex_a_c">
                            <div v-if="scope.row.carded == 0" class="icon-wenwukapian iconfont listIcon"></div>
                            <el-image
                                    style="width: 36px; height: 36px"
                                    :src="scope.row.picMasterViewUrl"
                                    :fit="'cover'"
                                    :preview-src-list="[scope.row.picMasterViewUrl]"
                            >
                                <div slot="error" class="image-slot">
                                    <el-image
                                            style="width: 36px; height: 36px"
                                            :src="require('@/assets/img/default.png')"
                                            :fit="'cover'">
                                    </el-image>
                                </div>
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="collectionName" label="藏品名称"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="helpNum" label="辅助账编号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="generalNum" label="总账编号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="entryState" label="归属账目" show-overflow-tooltip></el-table-column>
                <el-table-column prop="docuName" label="关联文献" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="relatedDetails(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:corr`)">
                            关联详情
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="collectionExpor(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:expor`)">
                            导出
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-table
                    v-show="selectTab == 1"
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable2"
                    :height="'calc(100vh - 360px)'"
                    class="table"
                    :row-key="'docuId'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="docuName" label="文献名称"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="collectionNames" label="关联藏品" show-overflow-tooltip></el-table-column>
                <el-table-column prop="docuAuthor" label="作者" show-overflow-tooltip></el-table-column>
                <el-table-column prop="tutorName" label="导师" show-overflow-tooltip></el-table-column>
                <el-table-column prop="keyword" label="关键词" show-overflow-tooltip></el-table-column>
                <el-table-column prop="digest" label="摘要" show-overflow-tooltip></el-table-column>
                <el-table-column prop="publishLand" label="出版地" show-overflow-tooltip></el-table-column>
                <el-table-column prop="publishThose" label="出版者" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createUserName" label="创建者"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="createDate" label="创建时间" width="150px">
                    <template slot-scope="scope">
                        {{ scope.row.createDate | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="updateUserName" label="最后更新人"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateDate" label="最后更新时间" width="150px"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.updateDate | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button v-if="selectTab == 1" style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(1, scope.row,scope.$index)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                            详情
                        </el-button>
                        <el-button v-if="selectTab == 1" style="margin-bottom: 10px" size="mini" type="text"
                                   @click="addData(2, scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:edit`)">修改
                        </el-button>
                        <el-button v-if="selectTab == 1" style="margin-bottom: 10px" size="mini" type="text"
                                   @click="deleteDate(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:delete`)">
                            删除
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="exportData(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:expor`)">
                            导出
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>


            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="current"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="size"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>


        <el-dialog
                title="关联的文献"
                :visible.sync="dialogVisible"
                width="800px"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :before-close="handleClose">
            <el-table
                    :data="seeeData"
                    size="small"
                    v-loading="seeeDataLoading"
                    height="400px"
                    class="table"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
            >
                <el-table-column label="序号" type="index" width="50"></el-table-column>
                <el-table-column prop="docuName" label="文献名称"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="collectionNames" label="关联藏品" show-overflow-tooltip></el-table-column>
                <el-table-column prop="docuAuthor" label="作者" show-overflow-tooltip></el-table-column>
                <el-table-column prop="tutorName" label="导师" show-overflow-tooltip></el-table-column>
                <el-table-column prop="keyword" label="关键词" show-overflow-tooltip></el-table-column>
                <el-table-column prop="digest" label="摘要" show-overflow-tooltip></el-table-column>
                <el-table-column prop="publishLand" label="出版地" show-overflow-tooltip></el-table-column>
                <el-table-column prop="publishThose" label="出版者" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createUserName" label="创建者"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="createDate" label="创建时间" width="150px">
                    <template slot-scope="scope">
                        {{ scope.row.createDate | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="updateUserName" label="最后更新人"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateDate" label="最后更新时间" width="150px"
                                 show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.updateDate | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="collDetail(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:detail`)">
                            详情
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="exportData(scope.row)"
                                   v-show="hasPermissionButton(`collection:business:${typePage}:expor`)">
                            导出
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="seeSizeChangeHandle"
                        @current-change="seeCurrentChangeHandle"
                        :current-page="seeCurrent"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="seeSize"
                        :total="seeTotal"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer text_center">
                <el-button size="small" @click="handleClose()">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "LiteratureList",
    props: {
        typePage: {
            type: String,
            default: 'LiteratureList',
        },
    },
    data() {
        return {
            selectTab: '1',
            tabsList: [
                {
                    name: '文献列表',
                    value: '1',
                },
                {
                    name: '藏品列表',
                    value: '2',
                },
            ],
            inputForm: {
                docuName: '',
                collectionName: '',
                docuAuthor: '',
                dataId: '',
                generalNum: '',
                //这个接口没有
                entryState: '',
            },

            appraisalTypeList: [
                {
                    value: 1,
                    label: '总账',
                }, {
                    value: 2,
                    label: '辅助帐',
                },
            ],

            searchRecord: {},
            dataListSelect: [],
            loading: false,
            dataList: [],

            pageNo2: 0,
            current: 1,
            size: 10,
            total: 0,

            dialogVisible: false,
            seeeData: [],
            seeeDataLoading: false,
            seltasiDataId: '',
            seeCurrent: 1,
            seeSize: 10,
            seeTotal: 0,
        }
    },

    mounted() {
        let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
        if (listSearch) {
            this.inputForm = listSearch.inputForm
            this.pageNo2 = listSearch.current
            this.size = listSearch.size
            this.selectTab = listSearch.selectTab
        }
        this.getDataList('', 1)
    },

    methods: {
        getDataList(type, dividePage) {
            if (type == 1) {
                this.current = 1
                this.seeCurrent = 1
            }
            if (dividePage) {
                this.$refs.multipleTable1.clearSelection()
                this.$refs.multipleTable2.clearSelection()
            }
            this.loading = true
            this.searchRecord = {
                ...this.inputForm,
                selectTab: this.selectTab,
                current: this.pageNo2 ? this.pageNo2 : this.current,
                size: this.size,
            }
            let feachUrl = this.api.collection.docuList
            if (this.selectTab == 2) {
                feachUrl = this.api.collection.docucollectionList
            }
            this.$axios(feachUrl, this.searchRecord, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total)
                    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
                    if (listSearch) {
                        this.current = listSearch.current
                        this.pageNo2 = 0
                        sessionStorage.removeItem('listSearch')
                    }
                    if (this.dataList.length == 0 && this.current != 1) {
                        this.current--;
                        this.getDataList()
                    }
                    this.$nextTick(() => {
                        this.$refs.multipleTable1.doLayout()
                        this.$refs.multipleTable2.doLayout()
                    })
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //num 0新增 1详情 2修改、重新提交 4审核
        addData(num, row, index) {
            if (num == 0) {
                this.$router.push({
                    path: '/collection/business/LiteratureManage/literatureDetail',
                    query: {butType: num},
                })
            } else {
                let listSearch = {
                    inputForm: this.inputForm,
                    selectTab: this.selectTab,
                    current: this.current,
                    size: this.size
                }
                sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
                this.$router.push({
                    path: '/collection/business/LiteratureManage/literatureDetail',
                    query: {butType: num, docuId: row.docuId,},
                })
            }
        },


        deleteDate(row) {
            this.$confirm(`若删除文件，关联的藏品均会与文献解绑，确认删除吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.collection.docuRemoveById + row.docuId, {}, 'delete').then(data => {
                    if (data && data.status) {
                        this.$message.success('删除成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        resetting() {
            this.$refs.inputForm.resetFields();
            this.inputForm.entryState = ''
            this.getDataList(1, 1)
        },
        bulkExport() {
            if ("1" === this.selectTab) {
                this.exportData();
            } else {
                this.collectionExpor();
            }
        },
        //导出
        exportData(row) {
            let ids = []
            let filename = "";
            if (row) {
                ids = [row.docuId]
                filename = row.docuName
            } else {
                if (this.dataListSelect.length == 0) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                filename = "文献批量导出"
                ids = this.dataListSelect.map(item => {
                    if (item) {
                        return item.docuId
                    }
                })
            }
            this.$message.info('文件已开始下载，请勿重复操作')
            this.$axios(this.api.collection.docuExport, ids, 'post', 'blob').then(res => {
                let blob = new Blob([res.data], {type: 'application/zip'})
                let link = document.createElement('a')
                link.download = decodeURI(filename)
                link.href = window.URL.createObjectURL(blob)
                link.click()
            })

            this.getDataList('', 1)
        },
        collectionExpor(row) {
            let ids = []
            let filename = ""
            if (row) {
                ids = [row.dcId]
                filename = row.collectionName + "藏品关联文献";
            } else {
                if (this.dataListSelect.length == 0) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                filename = "藏品文献批量导出"
                ids = this.dataListSelect.map(item => {
                    if (item) {
                        return item.dcId
                    }
                })
            }
            this.$message.info('文件已开始下载，请勿重复操作')
            this.$axios(this.api.collection.docucollectionExport, ids, 'post', 'blob').then(res => {
                let blob = new Blob([res.data], {type: 'application/zip'})
                let link = document.createElement('a')
                link.download = decodeURI(filename)
                link.href = window.URL.createObjectURL(blob)
                link.click()
            })
            this.getDataList('', 1)
        },
        setAge(age) {
            this.inputForm[age] = this.inputForm[age].replace(/[^0-9.]/g, '')
            if (this.inputForm[age] > 200) {
                this.inputForm[age] = 200
            }
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        handleClick(tab, event) {
            this.resetting()
            this.getDataList(1, 1)
            this.pageNo = 1
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', '');
        },

        //
        collDetail(row) {
            this.$router.push({
                path: '/collection/business/LiteratureManage/literatureDetail',
                query: {butType: 1, docuId: row.docuId,},
            })
        },

        //关联详情
        relatedDetails(row) {
            this.dialogVisible = true
            this.getSeeData(1, row)
        },

        handleClose() {
            this.dialogVisible = false
        },

        getSeeData(num, row) {
            if (num) {
                this.seeCurrent = 1
            }
            if (row) {
                this.seltasiDataId = row.dataId
            }
            this.seeeDataLoading = true
            let data = {
                current: this.seeCurrent,
                size: this.seeSize,
                dataId: this.seltasiDataId
            }
            this.$axios(this.api.collection.relevanceList, data, 'get').then((res) => {
                if (res.status) {
                    this.seeeData = res.data.records
                    this.seeTotal = parseInt(res.data.total)
                } else {
                    this.$message.error('查询失败');
                }
                this.seeeDataLoading = false
            })
        },

        // 展览每页数
        seeSizeChangeHandle(val) {
            this.seeSize = val;
            this.getSeeData(1)
        },
        // 展览当前页
        seeCurrentChangeHandle(val) {
            this.seeCurrent = val;
            this.getSeeData();
        },
    },
}
</script>

<style scoped>
.bg-white {
    overflow-y: auto !important;
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
    position: static !important;
}
</style>
